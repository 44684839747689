import React from 'react';
import PropTypes from 'prop-types';
// Utility imports
import Image from '@components/utility/Image';

const Stage = ({image, imageAlt, heading, children, addedClass}) => (
	<div className={`stage-point flex flex-col items-center md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-20 ${addedClass}`}>
		<div className='stage-icon self-start md:self-center'>
			<Image
				imageData={image}
				alt={imageAlt}
				loading='eager'
				objectFit='contain'
			/>
		</div>
		<div className='stage-content max-w-sm'>
			<h2 className='font-bold text-emflaza-blue-200 text-2xl uppercase mb-5'>{heading}</h2>
			{children}
		</div>
	</div>
);

Stage.defaultProps = {
	imageAlt: "",
	addedClass: "",
};

Stage.propTypes = {
	image: PropTypes.object.isRequired,
	imageAlt: PropTypes.string,
	heading: PropTypes.string.isRequired,
	children: PropTypes.node,
	addedClass: PropTypes.string,
}

export default Stage;

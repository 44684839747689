import React, { useContext, useEffect } from 'react';
import { KfHeroUtility, isMobile } from '@klickinc/kf-react-components';
import Loadable from '@loadable/component';
import AppContext from "@src/context";
// Utility imports
import Image from '@components/utility/Image';
import Seo from '@components/utility/SEO';
import { graphql } from 'gatsby';
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import './styles.scss';

import BottomCta from '@components/content/BottomCta';
import Stage from '@components/page-sections/understanding-corticosteroids/Stage';
import Sparkles from '@components/content/svgs/Sparkles';

const VideoPlayer = Loadable(() => import("@components/content/VideoPlayer.jsx"));


const UnderstandingCorticosteroids = ({ data }) => {
	const ctx = useContext(AppContext);

	const image_bg1 = withArtDirection(getImage(data.bg1), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.bg1M),
		},
	]);
	const image_heroWhizzbangLine = getImage(data.heroWhizzbangLine);
	const image_heroIcon1 = getImage(data.heroIcon1);
	const image_bg2 = getImage(data.bg2);
	const image_stageIcon1 = getImage(data.stageIcon1);
	const image_stageIcon2 = getImage(data.stageIcon2);
	const image_stageIcon3 = getImage(data.stageIcon3);
	const image_bg3 = getImage(data.bg3);

	const switchList = [
		{
			image: 'strength.png',
			alt: 'Strength',
			addedClass: 'strength',
			description: 'Has there been a shift in his muscle strength or function?',
			imageData: getImage(data.strength),
		},
		{
			image: 'weight.png',
			alt: 'Weight',
			addedClass: 'weight',
			description: 'Do the gains feel excessive?',
			imageData: getImage(data.weight),
		},
		{
			image: 'independence.png',
			alt: 'Independence',
			addedClass: 'independence',
			description: 'Has his disease progressed?',
			imageData: getImage(data.independence),
		},
		{
			image: 'time.png',
			alt: 'Time',
			addedClass: 'time',
			description: 'Are activities taking longer than before (eg, walking, standing, etc.)?',
			imageData: getImage(data.time),
		},
		{
			image: 'communication.png',
			alt: 'Communication',
			addedClass: 'communication',
			description: 'Has his emotional state shifted?',
			imageData: getImage(data.communication),
		},
		{
			image: 'health-status.png',
			alt: 'Health status',
			addedClass: 'health-status',
			description: 'Is he maintaining his quality of life?',
			imageData: getImage(data.healthStatus),
		},
	];

	useEffect(() => {
		ctx.setPageClass('page--understanding-corticosteroids');
	}, []);

	return (
		<>
			<section className='relative'>
				<div className='bg-1-image'>
					<Image
						imageData={image_bg1}
						alt=''
						loading='eager'
					/>
				</div>
				<div className="container container--inner">
					<div className='relative'>
						<KfHeroUtility addedClass='heading heading-hero-blue'>
							Differences in corticosteroids
						</KfHeroUtility>
						<div className='whizzbang-hero'>
							<Image
								imageData={image_heroWhizzbangLine}
								alt=''
								loading='eager'
							/>
						</div>
					</div>

					<h2 className='max-w-3xl mb-5 orange-heading'>Living with DMD, your son has unique functional and emotional needs; both&nbsp;are meaningful considerations when choosing a corticosteroid.</h2>
					<div className='hero-icon-content'>
						<div className='flex-1 max-w-[188px] md:max-w-[288px]'>
							<Image
								imageData={image_heroIcon1}
								alt='Behavioral, physical, functional & emotional well-being'
								loading='eager'
							/>
						</div>
						<div className='w-5/6 h-px bg-emflaza-blue-200 md:max-w-[1px] md:h-[16rem]'></div>
						<div className='flex-1 max-w-[300px]'>
							<ul className='space-y-5 bulleted-list'>
								<li className='flex space-x-3 '>Corticosteroids are often introduced soon after DMD diagnosis to ease inflammation and preserve muscle strength.</li>
								<li className='flex space-x-3 '>Every boy is different, so there are options when choosing a corticosteroid.</li>
								<li>
									<ul className='ml-4 circled-list'>
										<li className='flex'>There are two corticosteroids used to treat boys with DMD and they are not the same. You can work with your care team to understand both options, their indications, benefits, side effects, and how they can affect your son differently.</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>


			<section className='relative mt-12'>
				<div className='bg-2-image'>
					<Image
						imageData={image_bg2}
						alt=''
						loading='eager'
					/>
				</div>
				<div id="caring-for-dmd" className="container container--inner container--padded">
					<h2 className='heading heading-hero-blue'>Caring for dmd</h2>
					<p className='max-w-lg mb-5'>Your son's healthcare provider may turn to corticosteroids soon after DMD diagnosis; however, the corticosteroid your son starts on may not always be the best to stay on.</p>
					<p className='max-w-lg mb-5'>Your son's healthcare provider may need to increase the dosage as your child grows.</p>
					<h2 className='max-w-3xl my-5 orange-heading'>If your child is in one of the early stages shown below and you're not sure if they are receiving all they should from their current treatment, talk to your son's healthcare provider.</h2>
					<div className='flex flex-col space-y-5'>
						<Stage
							image={image_stageIcon1}
							heading='Early ambulatory'
						>
							<ul className='bulleted-list'>
								<li>Childhood</li>
								<li>Moving slower or with difficulty, frequent falls, muscle weakness, enlarged calves</li>
								<li>It's important to track developmental milestones and health status</li>
							</ul>
						</Stage>

						<Stage
							image={image_stageIcon2}
							heading='Late ambulatory'
						>
							<ul className='bulleted-list'>
								<li>Late childhood/adolescent/young adult</li>
								<li>Difficulty walking, may walk off-balance, weakened hands</li>
								<li>A scooter or wheelchair may help decrease fatigue</li>
							</ul>
						</Stage>

						<Stage
							image={image_stageIcon3}
							heading='Early non-ambulatory'
						>
							<ul className='bulleted-list'>
								<li>Adolescent/young adult</li>
								<li>Unable to walk, scoliosis (curved spine), muscle pain, weakness in arms</li>
								<li>This may be the time to track respiratory function twice a year</li>
							</ul>
						</Stage>
					</div>

					<p className='max-w-xl my-8 font-bold md:my-14'>Continuing corticosteroids throughout all stages of DMD can help him stay involved with friends and family for longer.</p>
				</div>
			</section>

			<section className='relative' id='understanding-switch'>
				<div className='bg-3-image'>
					<div className='md:hidden'>
						<Image
							imageData={image_bg3}
							alt=''
							loading='eager'
						/>
					</div>
				</div>
				<div className="container container--inner">
					<div className='pb-8 xl:py-16 md:pl-10'>
						<h2 className="heading heading-hero-blue">Considerations for&nbsp;switching corticosteroids</h2>
						<p className="mb-6 lg:max-w-[40rem]">If your son is currently on corticosteroids, tracking his physical and emotional development can help with future treatment decisions. As his symptoms change over time, asking yourself these questions may be helpful:</p>

						<div className='switch-container-outer'>
							<Sparkles height={`${isMobile() ? '400px' : '770px'}`} left='-left-4' />
							{
								switchList.map((item, i) => (
									<div key={`switch-${i}-${item.addedClass}`} className={`flex items-center justify-between h-12 md:h-auto space-x-3 switch__${item.addedClass}`}>
										<div className='flex-1 switch__image'>
											<Image imageData={item.imageData} alt={item.alt} />
										</div>
										<div className='flex-1 switch_container'>
											<p className='text-xs font-gotham md:text-xl md:pt-7'>{item.description}</p>
										</div>
									</div>
								))
							}
						</div>
					</div>
				</div>
			</section>

			<section id='understanding-corticosteroids'>
				<div className="container container--inner">
					<div className='pb-20 xl:py-16'>
						<h2 className="xl:pt-0 heading heading-hero-blue">Real-world story</h2>
						<p className='mb-8'>Hear from a family as they discuss their experience with making treatment decisions.</p>
						<div className='video'>
							<VideoPlayer id={644529296} />
							<a href='/video-transcript/he-is-manny' target='_blank' rel='noreferrer' className='gtm-transcript-cta transcript-cta'>View Video Transcript</a>
						</div>
						<BottomCta
							ctaUrl="/about-emflaza"
							image={data}
						>
							Why choose deflazacort?
						</BottomCta>
					</div>
				</div>
			</section>


		</>
	);
};

export default UnderstandingCorticosteroids;


export const pageQuery = graphql`
  query {
	bg1: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-bg-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bg1M: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-bg-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	heroWhizzbangLine: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-hero-whizzbang-line.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	heroIcon1: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-hero-icon-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bg2: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-bg-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	stageIcon1: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-stage-icon-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	stageIcon2: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-stage-icon-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	stageIcon3: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-stage-icon-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bg3: file(relativePath: {eq: "pages/understanding-corticosteroids/page-understanding-bg-3-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	strength: file(relativePath: {eq: "pages/understanding-corticosteroids/strength.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	weight: file(relativePath: {eq: "pages/understanding-corticosteroids/weight.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	independence: file(relativePath: {eq: "pages/understanding-corticosteroids/independence.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	time: file(relativePath: {eq: "pages/understanding-corticosteroids/time.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	communication: file(relativePath: {eq: "pages/understanding-corticosteroids/communication.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	healthStatus: file(relativePath: {eq: "pages/understanding-corticosteroids/health-status.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImage: file(relativePath: {eq: "pages/understanding-corticosteroids/bottom-cta/understanding-cta-image-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	leftImageMobile: file(relativePath: {eq: "pages/understanding-corticosteroids/bottom-cta/understanding-cta-image-1-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	iconImage: file(relativePath: {eq: "pages/understanding-corticosteroids/bottom-cta/understanding-cta-image-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImage: file(relativePath: {eq: "pages/understanding-corticosteroids/bottom-cta/understanding-cta-image-3.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	rightImageMobile: file(relativePath: {eq: "pages/understanding-corticosteroids/bottom-cta/understanding-cta-image-3-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
	return (
		<Seo title='Understanding Different Corticosteroids - EMFLAZA® (deflazacort)' description='His unique needs are meaningful considerations for choosing a corticosteroid. Learn about different types of corticosteroids and how they may affect him. See full Prescribing Information & Important Safety Information.' addSocialMeta={false}>
			<script type="application/ld+json">
				{JSON.stringify({ "@context": "http://schema.org", "type": "VideoObject", "name": "He is Manny", "description": "Hear from a family as they discuss their experience with making treatment decisions.", "thumbnailUrl": "https://i.vimeocdn.com/video/1303849706-128543bac7cd91c55fa120583dfdbcb11fd1bb163e1bf67b9", "uploadDate": "2022-09-30", "embedUrl": "https://www.emflaza.com/understanding-corticosteroids", "contentUrl": "https://player.vimeo.com/video/644529296", "duration": "PT8M20S" })}
			</script>
		</Seo>
	);
};
